.App {
  text-align: center;
  align-content: center;
  justify-content: center;
}

.App-logo {
  max-height: 30vh;
  pointer-events: none;
}

.App-header {
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  color: #E6365E;
}

.App-description {
  text-align: start;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

.Btn {
  font-size: large;
  font-weight: bold;
  border: none;
  padding: 15px;
  border-radius: 10px;
}

.Action-btn {
  background-color: #61dafb;
  color: white;
}

.Desctructive-btn {
  background-color: red;
  color: white;
  font-size: medium;
}

.Drive-connect {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Drive-logo {
  max-height: 4vh;
  min-height: 50px;
  pointer-events: none;
  padding: 0.5em;
}

.Forward-arrow {
  max-height: 3vh;
  min-height: 20px;
  pointer-events: none;
  padding-left: 1em;
  padding-right: 1em;
}

.Neutral-btn {
  background-color: lightgrey;
  color: black;
  font-size: medium;
}
